import moment from 'moment';

export const putBlobsToSignedUrls = async (results, assets) => {
  const finalResult = [];

  for (let i = 0; i < results.length; i++) {
    try {
      const file = results[i].file;
      if (!file.url) {
        finalResult.push({
          url: assets?.[i]?.name,
          type: assets?.[i]?.type,
          originalname: assets?.[i]?.name,
          status: 'failed',
          timestamp: moment().unix(),
          readable_timestamp: moment().format('LLL'),
          ...(assets?.[i]?.side && { side: assets?.[i]?.side }),
        });
        continue;
      }

      const apiResponse = await fetch(file.url, {
        method: 'PUT',
        body: assets?.[i].file,
        headers: { 'Content-Type': assets?.[i].type },
      });

      if (apiResponse.ok) {
        finalResult.push({
          url: process.env.REACT_APP_R2_PUBLIC_DOMAIN + '/' + file.name,
          type: file.category,
          name: file.name,
          originalname: file.originalName,
          status: 'uploaded',
          timestamp: moment().unix(),
          readable_timestamp: moment().format('LLL'),
          ...(assets?.[i]?.side && { side: assets?.[i]?.side }),
        });
      } else {
        finalResult.push({
          url: assets?.[i]?.name,
          type: assets?.[i]?.name,
          originalname: assets?.[i]?.name,
          status: 'failed',
          timestamp: moment().unix(),
          readable_timestamp: moment().format('LLL'),
          ...(assets?.[i]?.side && { side: assets?.[i]?.side }),
        });
      }
    } catch (error) {
      console.log('error', error);
      finalResult.push({
        url: assets?.[i]?.name,
        type: assets?.[i]?.type,
        originalname: assets?.[i]?.name,
        status: 'failed',
        timestamp: moment().unix(),
        readable_timestamp: moment().format('LLL'),
        ...(assets?.[i]?.side && { side: assets?.[i]?.side }),
      });
    }
  }
  return finalResult;
};
